import template from './bankauswahl.html';
import { PartnerIdSource } from '../../../../common/obs-url-params.service';

export {BankauswahlComponent, BankauswahlController};

const BankauswahlComponent = {
  template: template,
  controller: BankauswahlController,
  require: {
    parent: '^^kdfPositiv'
  },
  bindings: {
    banken: '<'
  }
};

function BankauswahlController(bankauswahlService, scrollService, urlParamsService, kontaktformularService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {

    ctrl.changeSelection = changeSelection;
    ctrl.showMore = showMore;
    ctrl.model = bankauswahlService.model;
    ctrl.parent.addValidator(validate);
    bankauswahlService.setBanken(ctrl.banken);
    //bereits getroffene Auswahl der Partner-Bank zurücksetzen, wenn neue Filialliste geliefert wird
    if (urlParamsService.partnerId && urlParamsService.isPartnerIdSource(PartnerIdSource.SELECTION) &&
        !bankauswahlService.isSelected()) {
      urlParamsService.partnerId = null;
      urlParamsService.partnerBankName =null;
      kontaktformularService.model.partnerId = null;
      kontaktformularService.model.partnerBankName = null;

    }
  };

  function changeSelection(bank) {
    bankauswahlService.changeSelection(bank);
    if (bank &&
      (urlParamsService.partnerId == null || urlParamsService.isPartnerIdSource(PartnerIdSource.SELECTION)))  {
      urlParamsService.partnerId = bank.blz;
      urlParamsService.partnerBankName = bank.bankname;
      urlParamsService.partnerIdSource = PartnerIdSource.SELECTION;
      kontaktformularService.model.partnerId = bank.blz;
      kontaktformularService.model.partnerBankName = bank.bankname;

      kontaktformularService.model.bankCandidate.bankname = bank.bankname;
      kontaktformularService.model.bankCandidate.blz = bank.blz;
      kontaktformularService.model.bankCandidate.ort = bank.ort;
      kontaktformularService.model.bankCandidate.plz = bank.plz;
      kontaktformularService.model.bankCandidate.strasse = bank.strasse;
      kontaktformularService.model.bankCandidate.strasse = bank.strasse;
      kontaktformularService.model.cachedGetBankInfoAnfrage = bank.blz;
    }
  }

  function showMore() {
    bankauswahlService.showMore();
  }

  function validate() {
    if (!bankauswahlService.isSelected()) {
      ctrl.model.error = true;
      scrollService.scrollTo('bankSelection');
      return false;
    } else {
      ctrl.model.error = false;
      return true;
    }
  }

}
