import template from './legitimation-info.html';
import './_legitimation-info.scss';

export {LegitimationInfoComponent, LegitimationInfoController};

const LegitimationInfoComponent = {
  template: template,
  controller: LegitimationInfoController
};

/**
 * Controller für die Maske mit Hinweisen zur Legitimation (vor Start der Legitimation).
 *
 * @param popupService
 * @param messagesService
 *
 * @returns
 */
function LegitimationInfoController(popupService, messagesService, legitimationInfoService, scrollService, kontaktformularService, urlParamsService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.legitimationClicked = legitimationClicked;
    ctrl.isFilialeSelected = isFilialeSelected;
    ctrl.isVideoLegitimationSelected = isVideoLegitimationSelected;
    ctrl.messagesService = messagesService;
    ctrl.popup = popup;
    ctrl.toggleShowMore = toggleShowMore;
    ctrl.isShowMore = false;
  };

  function popup(type) {
    popupService.openAlert(type, 'Prozessschritt 4 - ');
  }

  function toggleShowMore(event) {
    ctrl.isShowMore = !ctrl.isShowMore;
    event.stopPropagation();
  }

  function legitimationClicked(legitimationArt) {
    if(legitimationArt === 'videoLegitimation') {
      legitimationInfoService.daten.filialeCheck = false;
      legitimationInfoService.daten.videoLegitimationCheck = true;
      scrollService.scrollTo('leftButton');
    } else if(legitimationArt === 'filiale') {
      // OBS mit PartnerId
      if(urlParamsService.partnerId != null)
        kontaktformularService.model.partnerId = urlParamsService.partnerId;
      kontaktformularService.getBankinformationen(kontaktformularService.model.partnerId);
      legitimationInfoService.daten.videoLegitimationCheck = false;
      legitimationInfoService.daten.filialeCheck = true;
      scrollService.scrollTo('leftButton');
    }
  }

  function isFilialeSelected() {
    return legitimationInfoService.filialeOK();
  }

  function isVideoLegitimationSelected() {
    return legitimationInfoService.videoLegitimationOK();
  }
}
