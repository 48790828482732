import { PartnerIdSource } from '../../common/obs-url-params.service';

export default class PartnerService {

  constructor(urlParamsService, obsRequestWrapper) {
    'ngInject'; //NOSONAR
    this._urlParamsService = urlParamsService;
    this._obsRequestWrapper = obsRequestWrapper;
    this._searchBankCalled = false;
  }

  /**
   * Überprüft die übergebene Partner-Id (BLZ) durch Aufruf des Bank-Services.
   * Wenn es sich um eine OBS-Bank handelt, wird sie als Partner-Bank übernommen.
   * Andernfalls greift die Auswahl der Partner-Bank über die IBAN des Kunden
   * (falls es eine Flexibel-Bank ist) oder über die Bankfilial-Suche.
   */
  resolvePartnerId () {
    // einmaliges Ermitteln des Namens der Partnerbank aufgrund der in der
    // Aufruf-URL übergebenen BLZ (Parameter partnerId)
    if (this._urlParamsService.isPartnerIdSource(PartnerIdSource.URL) &&
        this._urlParamsService.partnerId &&
        !this._searchBankCalled) {
      this._searchBankCalled = true;
      this._obsRequestWrapper.sendRequest('searchBank', {
        method: 'POST',
        useCustomErrorHandler: true, //kein Popup/Fehlerseite bei Error
        data: {'blz': this._urlParamsService.partnerId}
      }, false).then((res) => {
        // VRL-1131 nur noch OBS Banken zulassen!
        if (res.data.isObs == 'true') {
          this._urlParamsService.partnerBankName = res.data.name;
        } else {
          //keine OBS-Bank
          this._clearPartnerIdData();
        }
      }, () => {
        //ungültiger Parameter (keine gültige BLZ)
        this._clearPartnerIdData();
      });
    }
  }

  _clearPartnerIdData () {
    this._urlParamsService.partnerId = null;
    this._urlParamsService.partnerBankName = null;
    this._urlParamsService.partnerIdSource = null;
  }

}

