import angular from 'angular';
import componentModule from '../../components/components.module';
import commonModule from '../../common/common.module';
import LegitimationInfoService from './kdf-positiv/legitimation-info/legitimation-info.service';
import { EinwilligungComponent } from './kdf-positiv/einwilligung/einwilligung.component';
import { KdfPositivComponent } from './kdf-positiv/kdf-positiv.component';
import { BankauswahlComponent } from './kdf-positiv/bankauswahl/bankauswahl.component';
import { LegitimationInfoComponent } from './kdf-positiv/legitimation-info/legitimation-info.component';
import VideoLegitimationService from './video-legitimation/video-legitimation.service';
import { VideoLegitimationComponent } from './video-legitimation/video-legitimation.component';
import { VideoLegitimationFailoverComponent } from './video-legitimation/video-legitimation-failover.component';

export default angular
  .module('obs.legitimation', [
    commonModule,
    componentModule
  ])
  .service('legitimationInfoService', LegitimationInfoService)
  .service('videoLegitimationService', VideoLegitimationService)
  .component('legitimation', VideoLegitimationComponent)
  .component('videoLegitimationFailover', VideoLegitimationFailoverComponent)
  .component('legitimationInfo', LegitimationInfoComponent)
  .component('einwilligung', EinwilligungComponent)
  .component('kdfPositiv', KdfPositivComponent)
  .component('bankauswahl', BankauswahlComponent)
  .name;
