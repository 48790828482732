import _ from 'lodash';
import template from './kdf-positiv.html';
import { ValidatorContainer } from '../../../common/obs-form-utils.service';
import { applikationStatusLegitimation } from '../../../layout/navigation/navigation.constants';
import { PartnerIdSource } from '../../../common/obs-url-params.service';

export {KdfPositivComponent, KdfPositivController};

const KdfPositivComponent = {
  template: template,
  controller: KdfPositivController,
  bindings: {
    banken: '<'
  }
};

/**
 * Controller für die Maske bei positiver KDF-Prüfung.
 */
function KdfPositivController($state, $window, urlParamsService, piwikService, anfragedatenService, videoLegitimationService,
  applikationStatus, legitimationInfoService, kontaktformularService, kontaktdatenService, popupService) {
  'ngInject'; //NOSONAR
  const ctrl = this;
  const validatorContainer = new ValidatorContainer();

  ctrl.$onInit = function () {

    ctrl.daten = legitimationInfoService.daten;
    ctrl.model = kontaktformularService.model;

    ctrl.goToPrevPage = goToPrevPage;
    ctrl.goToNextPage = goToNextPage;

    ctrl.videoLegitimationOK = videoLegitimationOK;
    ctrl.filialeOK = filialeOK;
    ctrl.getButtonText = getButtonText;
    ctrl.isButtonVisible = isButtonVisible;

    ctrl.isBankauswahl = isBankauswahl;
    ctrl.addValidator = addValidator;
  };

  function getButtonText() {
    return legitimationInfoService.getButtonText();
  }

  function isButtonVisible() {
    return legitimationInfoService.isButtonVisible();
  }

  function videoLegitimationOK() {
    return legitimationInfoService.videoLegitimationOK();
  }

  function filialeOK() {
    return legitimationInfoService.filialeOK();
  }

  function addValidator(validator) {
    validatorContainer.addValidator(validator);
  }

  /*
    * Zurück-Button
    */
  function goToPrevPage() {
    $state.go('finanzkennzahlen');
  }

  /*
    * Weiter-Button
    */
  function goToNextPage() {
    const isValid = validatorContainer.validate();
    trackProcessStep(isValid);

    if (isValid && videoLegitimationOK()) {
      starteLegitimation();
    } else if (isValid && filialeOK()) {
      starteKontaktformular();
    }
  }

  /**
   * Entscheidet, ob die Liste der Flexibel Banken zur Auswahl angezeigt wird.
   */
  function isBankauswahl() {
    return !_.isEmpty(ctrl.banken) &&
      (!urlParamsService.partnerId || urlParamsService.isPartnerIdSource(PartnerIdSource.SELECTION));
  }

  /**
   * Start des Legitimationsprozesses über IDnow.
   * Diese Methode ruft den Service zum Stellen einer Anfrage auf und führt einen
   * Redirect auf den im Response angegebenen Legitimationslink aus
   */
  function starteLegitimation() {
    var legWin = $window.open('static/wait.html');
    anfragedatenService.flexibelAnfrage(false)
      .then(function (response) {
        // Weiterleiten auf Aktivierungslink Video-Legitimation
        if (response && response.data && response.data.legitimierungsLink) {
          legWin.location.href = response.data.legitimierungsLink;
          videoLegitimationService.legitimierungLink = response.data.legitimierungsLink;
          applikationStatus.status = applikationStatusLegitimation;
          $state.go('legitimation');
        }
      })
      .catch((response) => {
        legWin.close();
        if (response.data &&
            response.data.errorCode == 'LEGITIMATION_ANTRAG_FEHLER') {
          //Fehler bei der Anlage des Legitimationsauftrags bei IDnow
          //der Anwender kann wählen, ob er per E-Mail benachrichtigt wird und
          //die Legitimation später startet oder die Legitimation in der Bank
          //durchführt
          popupService.openConfirm('legitimationFehler')
            .then(() => {
              starteLegitimationFailover();
            })
            .catch(() => { /* Anwender kehrt zurück auf die Maske */ });
        } else {
          //sonstige technische Fehler
          handleError(response);
        }
      });
  }

  /**
   * Senden der Anfrage, ohne dass ein Legitimationsauftrag sofort
   * gesendet wird. Der Anwender erhält eine E-Mail, sobald der Auftrag
   * (im Hintergrund automatisch) erstellt wurde und die Legitimation
   * erfolgen kann.
   */
  function starteLegitimationFailover() {
    anfragedatenService.flexibelAnfrage(true)
      .then(function () {
        applikationStatus.status = applikationStatusLegitimation;
        $state.go('videoLegitimationFailover');
      })
      .catch((response) => {
        handleError(response);
      });
  }

  function starteKontaktformular() {
    kontaktformularService.model.daten.absprungpunkt = 'LEGITIMATION';

    // Übergebe Anfragedaten an Kontaktformular
    ctrl.model.daten.anfragedaten = anfragedatenService.getAnfrageRequest();

    kontaktformularService.starteKontaktformular($state, $window, kontaktdatenService);
  }

  /**
   * Error-Handling für Systemfehler in der Anfrage-Schnittstelle
   * (ausgenommen technische Fehler beim Aufruf der Video-Legitimation Schnittstelle).
   * Statt auf die Fehlerseite zu navigieren wird dem Anwender
   * ein Fehler-Popup angezeigt. So hat er die Möglichkeit, die Anfrage erneut zu stellen.
   * @param {*} response
   */
  function handleError(response) {
    if (response.data && response.data.meldungen) {
      popupService.openAlertFromServer(response.data.meldungen[0]);
    } else {
      popupService.openAlert('serverError');
    }
  }

  /**
   * Piwik-Tracking bei Wechsel zu Video-Legitimation
   */
  function trackProcessStep(success) {
    if (success) {
      const vertriebsPartnerBLZ = anfragedatenService.getAnfrageRequest().vertriebsPartnerBlz;
      piwikService.trackValidationSuccess([ vertriebsPartnerBLZ ]);
    }
    else {
      piwikService.trackValidationFailure([ 'Betreuende Bank wurde nicht gewählt' ]);
    }
  }


}

