import { ogrFrameMsg } from '../common/iframe-messaging';

/**
 * Konfiguration des Anfrage-Modules beim Start der Anwendung.
 */
export default function anfrageInit($rootScope, $window, $location, $state, cookieService, themesService,
  urlParamsService, endpointsService, piwikService, applikationStatus, IsDemoMode) {
  'ngInject'; //NOSONAR

  /**
   * Mit dem folgenen Block wird verhindert, dass der Anwender durch klick auf den
   * Back-Button des Browsers in der OBS hängen bleibt.
   */
  $rootScope.$on('$locationChangeStart', function (event, targetURL, sourceURL) {
    if (targetURL.indexOf('#/partner') >= 0 && sourceURL.indexOf('#/finanzierungsvorhaben') >= 0) {      
      if($window.history.length > 2){
        event.preventDefault();
        if(navigator.userAgent.indexOf('Firefox') != -1) {
          // Issue OBS-6465: Incase of firefox jumps 2 pages back if obs is opened in same window/tab
          $window.history.go(-2);
        } else {
          $window.history.back();
        }
      }else{
        $window.close();
      }
    }
  });

  //Partner-ID aus Cookie lesen, wenn nicht in der URL gefunden (kann bei Page Refresh passieren)
  if (!IsDemoMode && urlParamsService.partnerId == null) {
    let partnerId = cookieService.readPartnerIdCookie();
    if (partnerId) {
      urlParamsService.partnerId = partnerId;
    }
  }

  if (!endpointsService.useMocks && !endpointsService.useDemo) {
    piwikService.initPiwik();
    piwikService.setStateService($state);
    piwikService.setSegmentBlz($location.search().partnerId);
    piwikService.setEingangskanal(applikationStatus.eingangskanal);
    piwikService.readAndSetVisitorId();
  }

  //Theme in Abhängigkeit des Referrers setzen
  themesService.applyTheme($location.search().referrer);

  //Prüfung, ob Anwendung im iFrame läuft
  //Falls Aufruf im IFrame, class Marker im <body>-Tag setzen
  const runsInIframe = $rootScope.runsInIFrame = $window.self !== $window.top;
  if (runsInIframe) {
    ogrFrameMsg.initListener();
  }

  //Cookie-Banner einblenden, wenn nicht im iFrame und nicht im Demo-Modus
  if (!applikationStatus.initialized && !IsDemoMode) {
    cookieService.initialize();
  }
}
