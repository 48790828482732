
export default class LegitimationInfoService {

  constructor() {
    'ngInject'; //NOSONAR

    this.daten = {
      videoLegitimationCheck: null,
      filialeCheck: null
    };
  }

  videoLegitimationOK() {
    return this.daten.videoLegitimationCheck === true;
  }

  filialeOK() {
    return this.daten.filialeCheck === true;
  }

  getButtonText() {
    return (this.daten.videoLegitimationCheck ? 'Jetzt Legitimation und Bonitätsprüfung starten' : 'Jetzt Terminanfrage starten');
  }

  isButtonVisible(){
    return (this.daten.videoLegitimationCheck || this.daten.filialeCheck);
  }
}
