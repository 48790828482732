import template from './video-legitimation.html';

export {VideoLegitimationComponent, VideoLegitimationController};

const VideoLegitimationComponent = {
  template: template,
  controller: VideoLegitimationController
};

/**
 * Controller für die Maske mit Hinweisen zur Legitimierung.
 *
 * @param popupService
 * @param messagesService
 * @param videoLegitimationService
 *
 * @returns
 */
function VideoLegitimationController(popupService, messagesService, videoLegitimationService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.messagesService = messagesService;
    ctrl.getUrl = getUrl;
    ctrl.popup = popup;
    ctrl.showMore = showMore;
    ctrl.isShowMore = false;
  };

  function getUrl() {
    return videoLegitimationService.legitimierungLink;
  }

  function popup(type) {
    popupService.openAlert(type, 'Prozesschritt 4 - ');
  }

  function showMore() {
    ctrl.isShowMore = true;
  }

}
