import template from './einwilligung.html';

export {EinwilligungComponent, EinwilligungController};

const EinwilligungComponent = {
  template: template,
  controller: EinwilligungController
};

function EinwilligungController(popupService, legitimationInfoService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {
    ctrl.daten = legitimationInfoService.daten;
    ctrl.popup = popup;
  };

  function popup(type) {
    popupService.openAlert(type,'Prozesschritt 4 - ');
  }
}
