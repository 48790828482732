import _ from 'lodash';
import { PartnerIdSource } from '../../common/obs-url-params.service';

export {PartnerComponent, PartnerController};

const PartnerComponent = {
  controller: PartnerController
};

function PartnerController($state, $stateParams, urlParamsService, piwikService, cookieService, dateUtilService) {
  'ngInject'; //NOSONAR
  const ctrl = this;

  ctrl.$onInit = function () {

    let partnerId = $stateParams.partnerId;
    let betrag = $stateParams.betrag;
    let laufzeit = $stateParams.laufzeit;
    const referrer = $stateParams.referrer;
    const extReferenz = $stateParams.extReferenz;
    let domain = $stateParams.domain;
    let  u_check =  $stateParams.u_check ;
    let p_check =  $stateParams.p_check ;
    let u_name =  $stateParams.u_name ;
    let u_str =  $stateParams.u_str ;
    let u_plz =  $stateParams.u_plz ;
    let u_ort =  $stateParams.u_ort ;
    let u_gruendung =  $stateParams.u_gruendung ;
    let p_anrede =  $stateParams.p_anrede ;
    let p_vorname =  $stateParams.p_vorname ;
    let p_nachname =  $stateParams.p_nachname ;
    let p_str =  $stateParams.p_str ;
    let p_plz =  $stateParams.p_plz ;
    let p_ort =  $stateParams.p_ort ;
    let p_geburtsdatum =  $stateParams.p_geburtsdatum ;
    let p_geburtsort =  $stateParams.p_geburtsort;
    let p_staatsang =  $stateParams.p_staatsang ;
    let p_mobilnr =  $stateParams.p_mobilnr ;
    let p_email =  $stateParams.p_email ;
    let p_kontakt =  $stateParams.p_kontakt ;
    let iban =  $stateParams.iban ;
    let ja_typ =  $stateParams.ja_typ ;
    let ja_fkzAuspraegung = $stateParams.ja_fkzAuspraegung ;
    let ja1_datum =  $stateParams.ja1_datum ;
    let ja1_umsatz =  $stateParams.ja1_umsatz ;
    let ja1_abschreibung =  $stateParams.ja1_abschreibung ;
    let ja1_zinsen =  $stateParams.ja1_zinsen ;
    let ja1_gewinn =  $stateParams.ja1_gewinn ;
    let ja2_datum =  $stateParams.ja2_datum ;
    let ja2_umsatz =  $stateParams.ja2_umsatz ;
    let ja2_abschreibung =  $stateParams.ja2_abschreibung ;
    let ja2_zinsen =  $stateParams.ja2_zinsen ;
    let ja2_gewinn =  $stateParams.ja2_gewinn ;

    // Falls die Partner-ID (BLZ) nicht in der URL mitgegeben wird,
    // muss der Cookie ObsPartnerId geprüft werden;
    if (!partnerId) {
      partnerId = cookieService.readPartnerIdCookie();
    }

    if (partnerId) {
      urlParamsService.partnerId = partnerId;
      urlParamsService.partnerIdSource = PartnerIdSource.URL;
      cookieService.writePartnerIdCookie(partnerId);
    }

    // Cookies von Produktseite der Bank für Finanzierungsbetrag und Laufzeit prüfen
    const obsTeaserBetrag = cookieService.readTeaserBetragCookie();
    if (obsTeaserBetrag) {
      betrag = obsTeaserBetrag;
    }
    const obsTeaserLaufzeit = cookieService.readTeaserLaufzeitCookie();
    if (obsTeaserLaufzeit) {
      laufzeit = obsTeaserLaufzeit;
    }
    if (obsTeaserBetrag || obsTeaserLaufzeit) {
      const trackValue =
        obsTeaserBetrag ?
          (obsTeaserLaufzeit ? obsTeaserBetrag + ' / ' + obsTeaserLaufzeit : obsTeaserBetrag):
          obsTeaserLaufzeit;
      piwikService.trackStart('Slider Produktseite', trackValue);
    }

    if (betrag) {
      betrag = _.toNumber(betrag);
      if (betrag) {
        urlParamsService.betrag = betrag;
      }
    }

    if (laufzeit) {
      laufzeit = _.toNumber(laufzeit);
      if (laufzeit) {
        urlParamsService.laufzeit = laufzeit;
      }
    }

    if (referrer) {
      piwikService.trackStart('Referrer', referrer);
      urlParamsService.referrer = referrer;
    }

    if (extReferenz) {
      urlParamsService.extReferenz = extReferenz;
    }

    if (domain) {
      if (!/^(http|https)/.test(domain.toLowerCase())) {
        domain = 'https://' + domain;
      }

      urlParamsService.domain = decodeURI(domain);
    }

    if (u_check) {
      urlParamsService.u_check = (u_check === 'true') ? true : (u_check === 'false') ? false : null;
    }

    if (p_check) {
      urlParamsService.p_check = (p_check === 'true') ? true : (p_check === 'false') ? false : null;
    }

    if (u_name) {
      urlParamsService.u_name = u_name;
    }

    if (u_str) {
      urlParamsService.u_str = u_str;
    }

    if (u_plz) {
      urlParamsService.u_plz = u_plz;
    }

    if (u_ort) {
      urlParamsService.u_ort = u_ort;
    }
    if (u_gruendung){
      urlParamsService.u_gruendung = dateUtilService.stringToDate(u_gruendung,'JJJJ-MM-TT');
    }
    if (p_anrede){
      urlParamsService.p_anrede = (p_anrede == 'HERR') ? 'Herr': (p_anrede == 'FRAU') ? 'Frau' : null;
    }
    if (p_vorname){
      urlParamsService.p_vorname = p_vorname;
    }
    if (p_nachname){
      urlParamsService.p_nachname = p_nachname;
    }
    if (p_str){
      urlParamsService.p_str = p_str;
    }
    if (p_plz){
      urlParamsService.p_plz = p_plz;
    }
    if (p_ort){
      urlParamsService.p_ort = p_ort;
    }
    if (p_geburtsdatum){
      urlParamsService.p_geburtsdatum = dateUtilService.stringToDate(p_geburtsdatum,'JJJJ-MM-TT');
    }
    if (p_geburtsort){
      urlParamsService.p_geburtsort = p_geburtsort;
    }
    if (p_staatsang){
      urlParamsService.p_staatsang = p_staatsang;
    }
    if (p_mobilnr){
      p_mobilnr = p_mobilnr.trim();
      let mobilNummerOhneCode = (p_mobilnr.startsWith('+49')) ? p_mobilnr.replace('+49',''): (p_mobilnr.startsWith('49')) ? p_mobilnr.replace('49',''): null;
      urlParamsService.p_mobilnr =  mobilNummerOhneCode;
    }
    if (p_email){
      urlParamsService.p_email = p_email;
    }
    if (p_kontakt){
      urlParamsService.p_kontakt = (p_kontakt === 'true') ? true : (p_kontakt === 'false') ? false : null;
    }
    if (iban){
      urlParamsService.iban = iban;
    }
    if (ja_typ){
      urlParamsService.ja_typ = (ja_typ == 'GUV') ? 'GuV' : (ja_typ == 'EUR') ? 'EuR': null;
    }
    if (ja_fkzAuspraegung){
      urlParamsService.ja_fkzAuspraegung = (ja_fkzAuspraegung == 'BWA') ? 'BWA' : (ja_fkzAuspraegung == 'JAHRESABSCHLUSS') ? 'JAHRESABSCHLUSS': null;
    }
    if (ja1_datum){
      urlParamsService.ja1_datum = dateUtilService.stringToDate(ja1_datum,'JJJJ-MM-TT');
    }
    if (ja1_umsatz){
      urlParamsService.ja1_umsatz = _.toNumber(ja1_umsatz);
    }
    if (ja1_abschreibung){
      urlParamsService.ja1_abschreibung = _.toNumber(ja1_abschreibung);
    }
    if ( ja1_zinsen){
      urlParamsService.ja1_zinsen = _.toNumber(ja1_zinsen);
    }
    if (ja1_gewinn){
      urlParamsService. ja1_gewinn = _.toNumber(ja1_gewinn);
    }
    if (ja2_datum){
      urlParamsService.ja2_datum = dateUtilService.stringToDate(ja2_datum,'JJJJ-MM-TT');
    }
    if (ja2_umsatz){
      urlParamsService.ja2_umsatz = _.toNumber(ja2_umsatz);
    }
    if (ja2_abschreibung){
      urlParamsService.ja2_abschreibung = _.toNumber(ja2_abschreibung);
    }
    if (ja2_zinsen){
      urlParamsService.ja2_zinsen = _.toNumber(ja2_zinsen);
    }
    if (ja2_gewinn){
      urlParamsService.ja2_gewinn = _.toNumber(ja2_gewinn);
    }
    $state.go('finanzierungsvorhaben');
  };
}
