import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import commonModule from '../../common/common.module';
import PartnerService from './partner.service';
import { PartnerComponent } from './partner.component';

export default angular
  .module('obs.partner', [
    uiRouter,
    commonModule
  ])
  .component('partner', PartnerComponent)
  .service('partnerService', PartnerService)
  .name;
