/**
 * Der Service liefert alle Daten, die auf den Masken Finanzierungsvorhaben bis Unternehmen
 * eingegeben oder ermittelt worden sind in einem Objekt gesammelt zur Verwendung in den
 * REST-Services kdfPruefung und flexibelAnfrage (Anfrage).
 */
export default class AnfragedatenService {

  /**
   * Konstruktor
   * @param {*} urlParamsService
   * @param {*} finanzierungsvorhabenService
   * @param {*} kontaktdatenService
   * @param {*} finanzkennzahlenService
   * @param {*} obsRequestWrapper
   * @param {*} piwikService
   * @param {*} interessentenService
   */
  constructor(urlParamsService, finanzierungsvorhabenService, kontaktdatenService, finanzkennzahlenService,
    obsRequestWrapper, piwikService, dateUtilService, interessentenService) {
    'ngInject'; //NOSONAR
    this._urlParamsService = urlParamsService;
    this._finanzierungsvorhabenService = finanzierungsvorhabenService;
    this._kontaktdatenService = kontaktdatenService;
    this._finanzkennzahlenService = finanzkennzahlenService;
    this._obsRequestWrapper = obsRequestWrapper;
    this._piwikService = piwikService;
    this._dateUtilService = dateUtilService;
    this._interessentenService = interessentenService;
  }

  getAnfrageRequest(skipLegitimation) {
    const data = {};
    const params = this._urlParamsService.getData4Server();
    const firma = this._kontaktdatenService.model.personModel.gewaehlteFirma;
    const person = this._kontaktdatenService.model.personModel.daten;
    const finanzen = this._finanzkennzahlenService.model.daten;

    if (params.partnerId) {
      data['vertriebsPartnerBlz'] = params.partnerId;
    }
    if (params.referrer) {
      data['referrer'] = params.referrer;
    }
    if (params.extReferenz) {
      data['extReferenz'] = params.extReferenz;
    }
    data['finanzierung'] = {
      'wunschrate': this._finanzierungsvorhabenService.getWunschrate(),
      'finanzierungsbetrag': this._finanzierungsvorhabenService.getFinanzierungsbetrag(),
      'berechnungsart': this._finanzierungsvorhabenService.getBerechnungsart(),
      'laufzeit': this._finanzierungsvorhabenService.getLaufzeit()
    };

    const validIban = finanzen && finanzen.iban && finanzen.iban.replace(/\s/g, '').toUpperCase();
    data['firma'] = {
      'beDirectNr': firma.beDirectNr,
      'iban': validIban,
      'gruendungsdatum':  this._dateUtilService.getDate(firma.gruendungsdatum)
    };
    data['anfrageSteller'] = {
      'anrede': person.geschlecht,
      'vorname': person.vorname,
      'nachname': person.nachname,
      'geburtsdatum': this._dateUtilService.getDate(person.geburtstag),
      'geburtsort': person.geburtsort,
      'nationalitaet': person.nationalitaet,
      'einwilligungDatenweitergabe': person.datenweitergabe,
      'adresse': {
        'strasse': person.strasse,
        'plz': person.plz,
        'ort': person.ort,
        'land': person.land,
        'email': person.email,
        'mobilNr': '+49' + person.mobilnummer
      }
    };

    data['eingangskanal'] = 'OBS';


    data['jahresabschlussDaten'] = [
      {
        'typ': finanzen.finanzkennzahlenArt,
        'fkzAuspraegung': finanzen.fkzAuspraegung,
        'bilanzStichtag': this._dateUtilService.getDate(finanzen.bilanzstichtag1),
        'gewinn': finanzen.gewinn1,
        'umsatz': finanzen.umsatz1,
        'abschreibung': finanzen.abschreibung1,
        'zinsaufwand': finanzen.zinsaufwand1
      },
      {
        'typ': finanzen.finanzkennzahlenArt,
        'fkzAuspraegung': finanzen.fkzAuspraegung,
        'bilanzStichtag': this._dateUtilService.getDate(finanzen.bilanzstichtag2),
        'gewinn': finanzen.gewinn2,
        'umsatz': finanzen.umsatz2,
        'abschreibung': finanzen.abschreibung2,
        'zinsaufwand': finanzen.zinsaufwand2
      }
    ];

    data['skipLegitimation'] = skipLegitimation;
    data['visitorId'] = this._piwikService.visitorId;
    data['interessentenCode'] = this._interessentenService.iCode;

    return data;
  }

  /**
   * Start des Legitimationsprozesses über IDnow.
   * Diese Methode ruft den Service zum Stellen einer Anfrage auf und
   * trackt den Wunschbetrag nach Piwik.
   * @param {*} skipLegitimation
   */
  flexibelAnfrage(skipLegitimation) {
    const postData = this.getAnfrageRequest(skipLegitimation);
    return this._obsRequestWrapper

      .sendRequest('flexibel', {
        method: 'POST',
        timeout: 120000,
        data: postData,
        addServerInfo: true,
        useCustomErrorHandler: true
      })
      .then((response) => {
        if (response && response.data && response.data.legitimierungsLink) {
          this._piwikService.goalTrackAmount(1, postData.finanzierung.finanzierungsbetrag);
        }
        return response;
      });
  }

  /**
   * Setzt einen Server-Request ab (kdfPruefung), um die KDF-Prüfung anhand der
   * Kundendaten durchzuführen. Liefert ein Promise.
   */
  pruefeKdf() {
    return this._obsRequestWrapper
      .sendRequest('kdfPruefung', {
        method: 'POST',
        timeout: 60000,
        data: this.getAnfrageRequest()
      })
      .then((response) => {
        // KDF-Ergebnis im finanzkennzahlenService merken
        this._finanzkennzahlenService.kdfPositiv = (response.data.ergebnis === 'POSITIV');

      }
    );
  }
}
