import { applikationStatusBeantragung, applikationStatusLegitimation } from '../layout/navigation/navigation.constants';
import { PartnerIdSource } from '../common/obs-url-params.service';

export { routes, transitionChecks };

/**
 * Definition der UI-Routes des Anfrage-Moduls
 */
function routes($urlRouterProvider, $stateProvider) {
  'ngInject'; //NOSONAR

  // Default-Route, wenn keine andere Regel greift
  $urlRouterProvider.otherwise('/partner');

  /*
   * Im $stateProvider werden die einzelnen Anwendungsstatus definiert
   * und mit den zugehörigen Masken und Controllern konfiguriert.
   *
   * Diese bilden die Basis für die Navigation innerhalb der OBS
   */
  $stateProvider
    .state('partner', {
      containerClass: 'partner',
      url: '/partner?partnerId&betrag&laufzeit&referrer&extReferenz&domain' +
        '&u_check&p_check&u_name&u_str&u_plz&u_ort&u_gruendung&p_anrede&p_vorname' +
        '&p_nachname&p_str&p_plz&p_ort&p_geburtsdatum&p_geburtsort&p_staatsang&p_mobilnr' +
        '&p_email&p_kontakt&iban&ja_typ&ja1_datum&ja1_umsatz&ja1_abschreibung&ja1_zinsen' +
        '&ja1_gewinn&ja2_datum&ja2_umsatz&ja2_abschreibung&ja2_zinsen&ja2_gewinn',
      component: 'partner',
      viewTitle: 'Partner'
    })
    //  Erfassung des Finanzierungsbedarfs (Einstieg)
    .state('finanzierungsvorhaben', {
      containerClass: 'finanzierungsvorhaben',
      url: '/finanzierungsvorhaben',
      component: 'finanzierungsvorhaben',
      resolve: {
        kalkulationParameter: function (kalkulationParameterService) {
          'ngInject'; //NOSONAR
          return kalkulationParameterService.getKalkulationParameter();
        }
      },
      isApplicationInitState: true,
      viewTitle: 'Finanzierungsvorhaben'
    })
    // Erfassung der Kundendaten
    .state('kontaktdaten', {
      containerClass: 'kontaktdaten',
      url: '/kontaktdaten',
      component: 'kontaktdaten',
      resolve: {
        laenderliste: function (laenderlisteService) {
          'ngInject'; //NOSONAR
          return laenderlisteService.getStaatsangehoerigkeiten();
        },
        partnerIdResolved: function (partnerService) {
          'ngInject'; //NOSONAR
          partnerService.resolvePartnerId();
          return true;
        }
      },
      viewTitle: 'Kontaktdaten'
    })
    // Erfassung der Finanzkennzahlen
    .state('finanzkennzahlen', {
      containerClass: 'unternehmen',
      url: '/bonitaetspruefung',
      component: 'finanzkennzahlen',
      resolve: {
        kalkulationParameter: function (kalkulationParameterService) {
          'ngInject'; //NOSONAR
          return kalkulationParameterService.getKalkulationParameter();
        }
      },
      viewTitle: 'Unternehmen'
    })
    // Positive Durchführung einer KDF-Pruefung
    .state('kdfPositiv', {
      containerClass: 'unternehmen',
      url: '/kdfPruefung',
      component: 'kdfPositiv',
      resolve: {
        banken: function (bankfilialenSucheService, urlParamsService, kontaktdatenService) {
          'ngInject'; //NOSONAR
          return _getBanken(bankfilialenSucheService, urlParamsService, kontaktdatenService);
        }
      },
      viewTitle: 'KDF positiv'
    })
    // Negative KDF-Pruefung
    .state('kdfNegativ', {
      containerClass: 'unternehmen',
      url: '/kdfAbbruch',
      component: 'kdfNegativ',
      resolve: {
        banken: function (bankfilialenSucheService, urlParamsService, kontaktdatenService) {
          'ngInject'; //NOSONAR
          return _getBanken(bankfilialenSucheService, urlParamsService, kontaktdatenService);
        }
      },
      viewTitle: 'KDF negativ'
    })
    // Video-Legitimation
    .state('legitimation', {
      containerClass: 'legitimation',
      url: '/legitimation',
      component: 'legitimation',
      viewTitle: 'Legitimation'
    })
    // Video-Legitimation Failover
    .state('videoLegitimationFailover', {
      containerClass: 'legitimation',
      url: '/videoLegitimationFailover',
      component: 'videoLegitimationFailover',
      viewTitle: 'Legitimation'
    })
    // Wiedereinstieg eines Interessenten mit Link
    .state('interessent', {
      containerClass: 'interessent',
      url: '/interessent?iCode',
      isApplicationInitState: true,
      resolve: {
        serverStatus: function (interessentenService, $stateParams) {
          'ngInject'; //NOSONAR
          return interessentenService.getStatusFromServer($stateParams.iCode);
        }
      },
      //dynamisch den Interessenten-View-Component anhand des Status ermitteln
      componentProvider: function (serverStatus) {
        'ngInject'; //NOSONAR
        return serverStatus === 'INTERESSENTEN_CODE_NOT_FOUND' ? 'interessentCodeUngueltig' : 'interessent';
      }
    });
}

function _getBanken(bankfilialenSucheService, urlParamsService, kontaktdatenService) {
  //Filialsuche nur aufrufen, wenn keine Partner-ID gesetzt oder bereits eine Filiale ausgewählt wurde
  //letzteres ist notwendig, da der Anwender im Prozess zurückgehen kann, eine andere Firma auswählen kann
  //und deshalb mit neuer Firmen-Adresse eine neue Suche ausgeführt werden muss
  if (urlParamsService.partnerId == null || urlParamsService.isPartnerIdSource(PartnerIdSource.SELECTION)) {
    const banken = bankfilialenSucheService.getBanken({
      plz: kontaktdatenService.model.personModel.gewaehlteFirma.plz,
      strasse: kontaktdatenService.model.personModel.gewaehlteFirma.strasse
    });
    return banken;
  } else {
    return null;
  }
}

function transitionChecks(navigationService, kontaktdatenService, finanzkennzahlenService) {
  'ngInject'; //NOSONAR
  navigationService.addTransitionCheck('error', () => {
    //Navigation immer zulassen
  });
  navigationService.addTransitionCheck('partner', () => {
    //Navigation immer zulassen
  });
  navigationService.addTransitionCheck('videoLegitimationFailover', () => {
    //Navigation immer zulassen
  });
  navigationService.addTransitionCheck('finanzierungsvorhaben',
    (transition, appStatus) => {
      if (appStatus && appStatus != applikationStatusBeantragung) {
        transition.abort();
      }
    });
  navigationService.addTransitionCheck('kontaktdaten',
    (transition, appStatus, fromState) => {
      if (appStatus && appStatus != applikationStatusBeantragung) {
        transition.abort();
      } else if (!fromState) {
        transition.abort();
        navigationService.navigateToStartPage();
      }
    });
  navigationService.addTransitionCheck('finanzkennzahlen',
    (transition, appStatus, fromState) => {
      if (appStatus && appStatus != applikationStatusBeantragung) {
        transition.abort();
      } else if (!kontaktdatenService.isValid()) {
        transition.abort();
        if (!fromState) {
          navigationService.navigateToStartPage();
        }
      }
    });
  const kdfTransitionCheck = function (transition, appStatus, fromState) {
    if (appStatus && appStatus != applikationStatusBeantragung) {
      transition.abort();
    } else if (!kontaktdatenService.isValid() || !finanzkennzahlenService.isValid()) {
      transition.abort();
      if (!fromState) {
        navigationService.navigateToStartPage();
      }
    }
  };
  navigationService.addTransitionCheck('kdfPositiv', kdfTransitionCheck);
  navigationService.addTransitionCheck('kdfNegativ', kdfTransitionCheck);
  navigationService.addTransitionCheck('legitimation',
    (transition, appStatus, fromState) => {
      if (appStatus != applikationStatusLegitimation) {
        transition.abort();
        if (!fromState) {
          navigationService.navigateToStartPage();
        }
      }
    });
  navigationService.addTransitionCheck('interessent', () => {
    //Navigation immer zulassen
  });
}
