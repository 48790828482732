import '../../style/main.scss';
import 'cookieconsent/build/cookieconsent.min.css';
import 'cookieconsent';
import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngLocale from 'angular-i18n/de-de';
import uiRouter from '@uirouter/angularjs';
import uiMask from 'angular-ui-mask';
import collapseModule from 'angular-ui-bootstrap/src/collapse';
import commonModule from '../common/common.module';
import componentModule from '../components/components.module';
import layoutModule from '../layout/layout.module';
import sharedModule from '../shared/shared.module';
import partnerModule from './partner/partner.module';
import finanzierungsvorhabenModule from './finanzierungsvorhaben/finanzierungsvorhaben.module';
import kontaktdatenModule from './kontaktdaten/kontaktdaten.module';
import unternehmenModule from './unternehmen/unternehmen.module';
import legitimationModule from './legitimation/legitimation.module';
import { obsInit, initStateChange, initErrorHandler } from '../app.init';
import { routes, transitionChecks } from './anfrage-routes';
import anfrageInit from './anfrage-init';
import AnfragedatenService from './anfragedaten.service';
import interessentModule from './interessent/interessent.module';

/**
 * Das Modul obs.abschluss enthält alle Masken und Funktionalitäten
 * nach der Legitimation.
 */
export default angular
  .module('obs.anfrage', [
    ngAnimate,
    ngLocale,
    uiRouter,
    uiMask,
    collapseModule,
    commonModule,
    componentModule,
    layoutModule,
    sharedModule,
    partnerModule,
    finanzierungsvorhabenModule,
    kontaktdatenModule,
    unternehmenModule,
    legitimationModule,
    interessentModule
  ])
  .service('anfragedatenService', AnfragedatenService)
  .config(routes)
  .run(obsInit)
  .run(initStateChange)
  .run(initErrorHandler)
  .run(transitionChecks)
  .run(anfrageInit);

